import React from "react"
import { Link } from "gatsby"

const Navigation = () => (
  <>
    <aside className="navigation">
      <div className="nav-wrapper">
        <div className="site-logo">
          <Link to="/">Bri</Link>
        </div>
        <nav className="site-links" role="navigation">
          <ul>
            <li>
              <Link to="about">About</Link>
            </li>
            <li>
              <Link to="projects">Projects</Link>
            </li>
            <li>
              <Link to="contact">Contact</Link>
            </li>
          </ul>
        </nav>
        <section className="social-links">
          <a href="mailto:briana.dacoscos@gmail.com" target="_blank">
            <i class="far fa-envelope"></i>
          </a>
          <a href="https://github.com/bdacoscos" target="_blank">
            <i class="fab fa-github"></i>
          </a>
          <a href="https://www.linkedin.com/in/bkdacoscos/" target="_blank">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="https://twitter.com/bdasnachos" target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
        </section>
      </div>
    </aside>
  </>
)

export default Navigation
